<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img width="80" :src="require('@/assets/images/logo/vuexy-logo.png')" />
        <h1 class="text-primary">SmartFarm</h1>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="7"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Selamat Datang di SmartFarm 👋
            
          </b-card-title>
          
          <b-card-text class="mb-2">
            Silahkan masuk melalui akun Anda dan memulai ternak bersama SmartFarm.
          </b-card-text>

          <b-alert
            v-height-fade.appear
            variant="danger"
            :show="showErrorAuth"
            class="mb-0"
          >
            <div class="alert-body">
              <feather-icon
                icon="InfoIcon"
                class="mr-50"
              />
              Nama Pengguna atau Kata Sandi Salah!
            </div>
          </b-alert>

          <b-alert
            v-height-fade.appear
            variant="success"
            :show="showSuccessAuth"
            class="mb-0"
          >
            <div class="alert-body">
              <feather-icon
                icon="InfoIcon"
                class="mr-50"
              />
              Login berhasil, tunggu sejenak anda akan dialihkan ke dashboard SmartFarm
            </div>
          </b-alert>

          <!-- form -->
          <validation-observer ref="loginForm">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Nama Pengguna"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nama Pengguna"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="Input nama pengguna"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Kata Sandi</label>
                  <b-link :to="{name:'auth-forgot-password-v2'}">
                    <small>Lupa kata sandi?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Kata Sandi"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Ingat Saya
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="login()"
              >
                Masuk
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>Belum punya akun? </span>
            <b-link >
              <span v-b-popover.hover.bottom="'Fitur sedang dikembangkan'">&nbsp;Kontak Administrator Sekolah</span>
            </b-link>
          </b-card-text> -->

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  VBPopover, BAlert, BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    VBPopover,
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  setup(){
    const showErrorAuth = ref(false);
    const showSuccessAuth = ref(false);
    return  { 
      showErrorAuth,
      showSuccessAuth
    }
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
    async login() {
    // Form Validation
      this.$refs.loginForm.validate().then(async (success ) => {
        // If form validation is successful
        if (success) {
          await store.dispatch("auth/login",  {credential: this.userEmail, password: this.password})
          .then((response) => {
            this.$ability.update(response.ability)
            this.showSuccessAuth = true;
            this.$router.replace('/dashboard')
            .then(() => {
              
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Welcome ${userData.name || userData.username}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `Selamat Datang ${userData.roles[0].name} di SmartFarm`,
                },
              })
            })
          })
          .catch((error) => {
            this.showErrorAuth = true
          })
        }
      })
      .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
      })
    },
  } 
}

</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
